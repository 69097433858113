<template>
    <div class="height-center loader-blue">
        <div v-if="sessionsError == false" class="d-flex flex-column align-center">
                <img src="/img/logo_floa_negative.svg"
                     class="logo"
                     alt="Logo de Floa"/>
            <div>
                <img src="/img/loader_marguerite.svg" 
                    alt="Chargement en cours" 
                />

            </div>

        </div>
        <div class="height-center" v-else>
            <error></error>
        </div>
    </div>
</template>

<script>
    import Error from './../Error.vue';

    export default {
        name: 'LoaderBlueFloa',
        components: {
            Error,
        },
        props: {
            sessionsError: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style scoped>
    @import '../../assets/css/loading-spinner.css';
    .logo {
        height: 200px;
        width: 300px;
    }
</style>

<style>
    .v-application--wrap {
        background-color: #009fff;
    }
</style>